import { Title } from "../Title/Title";
import "./Offers.css";
import banner1 from "../../assets/images/school_main.jpeg";
import banner2 from "../../assets/images/club_main.jpeg";
import { Link } from "react-router-dom";

export const Offers = () => {
  return (
    <section className="offers" id="offers-wrap">
      <Title title="Что я могу предложить?" />
      <div className="offers-grid">
        <div
          className="offers-photo order-1"
          style={{ backgroundImage: `url(${banner1})` }}
        ></div>
        <div
          className="offers-info order-2"
          style={{ alignItems: "flex-start" }}
        >
          <h3 className="offers-title">Онлайн школа танцев</h3>
          <p className="offers-text">
            Выбери программу подходящую именно тебе и погрузись в танец с
            головой, почувствую на себе атмосферу нашего зала вместе с другими
            учениками
          </p>
          <Link to="/school" className="offers-button">
            Узнать больше
          </Link>
        </div>
        <div className="offers-info order-3 info-right">
          <h3 className="offers-title">Закрытый клуб</h3>
          <p className="offers-text text-right">
            Испытай на себе авторский подход по глубокому познанию своего тела и
            разума тех, кто хочет понять себя, развиваться и менять свою жизнь
          </p>
          <Link to="/club" className="offers-button">
            Узнать больше
          </Link>
        </div>
        <div
          className="offers-photo order-4"
          style={{ backgroundImage: `url(${banner2})` }}
        ></div>
      </div>
    </section>
  );
};
