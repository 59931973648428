import "./Footer.css";
import insta from "../../assets/icons/insta.png";
import telega from "../../assets/icons/telegram.png";

export const Footer = () => {
  return (
    <footer className="footer-wrap">
      <div className="content footer">
        <div className="footer-contacts">
          <a
            href="https://www.instagram.com/nesterovichofficial?igsh=MWwxdjhqdHI1a2M1cg=="
            className="footer-contacts-link"
          >
            <img src={insta} alt="" />
            <p>Моя инста</p>
          </a>
          <a
            href="https://t.me/nesterovichofficial"
            className="footer-contacts-link"
          >
            <img src={telega} alt="" />
            <p>Мой телеграм</p>
          </a>
        </div>
        <div className="footer-docs">
          {/*<Link to="/" className="footer-docs-link">
            Публичная оферта
          </Link>*/}
        </div>
      </div>
    </footer>
  );
};
