import { ScrollRestoration } from "react-router-dom";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { ClubInfo } from "../components/ClubInfo/ClubInfo";
import { ClubSections } from "../components/ClubSections/ClubSections";
import { Thought } from "../components/Thought/Thought";
import { Footer } from "../components/Footer/Footer";

export const Club = () => {
  return (
    <main>
      <ScrollRestoration />
      <PageTitle title="Закрытый клуб" />
      <ClubInfo />
      <ClubSections />
      <Thought text="Пойми себя и начни вдохновлять других" />
      <Footer />
    </main>
  );
};
