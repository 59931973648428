import "./Thought.css";
import { ThoughtProps } from "./types/thought.type";

export const Thought = ({ text }: ThoughtProps) => {
  return (
    <section className="thought content">
      <p className="thought-text">{text}</p>
    </section>
  );
};
