import "./Text.css";
import { TextProps } from "./types/text.type";

export const Text = ({ text, width, margin, textAlign }: TextProps) => {
  return (
    <p className="text" style={{ width, margin, textAlign }}>
      {text}
    </p>
  );
};
