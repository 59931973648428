import { Text } from "../Text/Text";
import { Title } from "../Title/Title";
import banner1 from "../../assets/images/club_prog1.jpeg";
import "./ClubSections.css";

export const ClubSections = () => {
  return (
    <section className="club-sections">
      <Title title="Как попасть в клуб?" />
      <Text
        width="67rem"
        margin="3rem 0"
        textAlign="center"
        text="Для простоты и удобства все материалы будут выкладываться на Boosty, где можно оформить ежемесячную подписку и получить доступ к необходимым материалам"
      />
      <div className="club-sections-grid">
        <div
          className="club-sections-photo order-1"
          style={{ backgroundImage: `url(${banner1})` }}
        ></div>
        <div
          className="club-sections-info order-2"
          style={{ alignItems: "flex-start" }}
        >
          <h3 className="club-sections-title">Что же будет в клубе?</h3>
          <p className="club-sections-text">
            Вы начнете работать со своим телом для лучшего понимания прочессов в
            нем. Уверенность в себе вырастет, появится понимание своих желаний и
            вектор развития
            <br />
            <br />
            Начнётся плотное и взаимное общение с единомышленниками и
            одноклубниками, так как у нас общие темы и стремление к развитию
          </p>
          <a
            href="https://boosty.to/"
            className="club-sections-button"
            target="blank"
          >
            Перейти на Boosty
          </a>
        </div>
      </div>
    </section>
  );
};
