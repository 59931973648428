import "./ClubInfo.css";
import photo from "../../assets/images/club_banner.jpeg";

export const ClubInfo = () => {
  return (
    <section className="content club-info">
      <div
        className="club-info-img"
        style={{ backgroundImage: `url(${photo})` }}
      ></div>
      <p className="club-info-text">
        Уникальность нашего клуба заключается в авторском подходе, который
        включает глубокое познание своего тела и психики, а также развитие тела
        и ума. Мой огромный опыт в танцах позволил мне углубиться в процессы,
        увеличить знания о теле, психологии, философии и освоить множество
        танцевальных стилей. В какой-то момент все это сошлось воедино, и я
        понял, что танец — это не просто движение, а ключ к лучшей жизни во всех
        её аспектах. Теперь это не только танцы, но и практика, философия,
        анатомия, психология и различные методики, включая
        телесно-ориентированную терапию.
      </p>
    </section>
  );
};
