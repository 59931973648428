import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Main } from "./pages/Main";
import { School } from "./pages/School";
import { Club } from "./pages/Club";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      children: [
        {
          index: true,
          element: <Main />,
        },
        {
          path: "school",
          element: <School />,
        },
        {
          path: "club",
          element: <Club />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
