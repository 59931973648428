import { Text } from "../Text/Text";
import { Title } from "../Title/Title";
import banner1 from "../../assets/images/school_prog1.jpeg";
import banner2 from "../../assets/images/school_prog2.jpeg";
import banner3 from "../../assets/images/school_prog3.jpeg";
import "./SchoolSections.css";

export const SchoolSections = () => {
  return (
    <section className="school-sections">
      <Title title="Блоки программы обучения" />
      <Text
        width="67rem"
        margin="3rem 0"
        textAlign="center"
        text="Для простоты и удобства все материалы будут выкладываться на Boosty, где можно оформить ежемесячную подписку на нужный модуль обучения или перейти на более продвинутый, если это необходимо"
      />
      <div className="school-sections-grid">
        <div
          className="school-sections-photo order-1"
          style={{ backgroundImage: `url(${banner1})` }}
        ></div>
        <div
          className="school-sections-info order-2"
          style={{ alignItems: "flex-start" }}
        >
          <h3 className="school-sections-title">Работа с телом</h3>
          <p className="school-sections-text">
            Развитие пластики, объема, фокуса, координации и контроля тела - все
            происходит в удобном темпе и рассчитано на максимальное развитие
            тела
            <br />
            <br />
            Этот модуль доступен для всех, независимо от уровня подготовки
          </p>
          <a
            href="https://boosty.to/"
            className="school-sections-button"
            target="blank"
          >
            Перейти на Boosty
          </a>
        </div>
        <div
          className="school-sections-info order-3 info-right"
        >
          <h3 className="school-sections-title">
            Работа с базовыми движениями
          </h3>
          <p className="school-sections-text text-right">
            Освоение грува, работа с музыкой, компоновка базовых движений и их
            танцевание под музыку в различных темпах
            <br />
            <br />
            Этот блок предназначен для тех, кто хочет продвинуться в своем
            развитии, понять, как двигаться под музыку, работать с темпом и
            осваивать базовые движения для дальнейшего развития в танце
          </p>
          <a
            href="https://boosty.to/"
            className="school-sections-button"
            target="blank"
          >
            Перейти на Boosty
          </a>
        </div>
        <div
          className="school-sections-photo order-4"
          style={{ backgroundImage: `url(${banner2})` }}
        ></div>
        <div
          className="school-sections-photo order-5"
          style={{ backgroundImage: `url(${banner3})` }}
        ></div>
        <div
          className="school-sections-info order-6"
          style={{ alignItems: "flex-start" }}
        >
          <h3 className="school-sections-title">
            Работа с танцевальными связками
          </h3>
          <p className="school-sections-text">
            развитие танцевальной и мышечной памяти, работа с формой, музыкой,
            последовательностью, исполнением и проявлением
            <br />
            <br />
            Этот блок предназначен для тех, кто хочет развить свои навыки в
            постановочной модели, учить хореографию и танцевальные связки. Для
            лучшей подготовки рекомендуется пройти первые два модуля или
            осваивать все блоки в связке.
          </p>
          <a
            href="https://boosty.to/"
            className="school-sections-button"
            target="blank"
          >
            Перейти на Boosty
          </a>
        </div>
      </div>
    </section>
  );
};
