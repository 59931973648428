import "./SchoolInfo.css";
import photo from "../../assets/images/school_banner.jpeg";

export const SchoolInfo = () => {
  return (
    <section className="content school-info">
      <div
        className="school-info-img"
        style={{ backgroundImage: `url(${photo})` }}
      ></div>
      <p className="school-info-text">
        Программа рассчитана на полное погружение в процесс, максимально
        приближая к настоящему оффлайновому занятию. Все происходит в
        танцевальном зале с учениками и педагогом, что позволяет лучше
        погрузиться в процесс благодаря ощущению нахождения в зале со всеми.
        Уникальность заключается в том, что педагог, наблюдая за учениками,
        понимает, как и сколько нужно выполнять то или иное упражнение. Педагог
        чувствует атмосферу в зале и направляет в правильное русло, ведь настрой
        и правильная энергия занятия — важнейшая составляющая. Темп и скорость
        всегда настроены под учеников, поэтому больше не нужно перематывать и
        останавливать. Все происходит в одном потоке с молниеносной обратной
        связью, так как ученики задают вопросы и сразу получают ответы.
        Объяснение происходит в формате “здесь и сейчас”, что позволяет сразу
        применять все на практике.
      </p>
    </section>
  );
};
