import { Text } from "../Text/Text";
import { Title } from "../Title/Title";
import "./About.css";
import photo1 from "../../assets/images/move1.jpeg";
import photo2 from "../../assets/images/move2.jpeg";
import photo3 from "../../assets/images/move3.jpeg";

export const About = () => {
  return (
    <section className="about content">
      <Title title="Кто я такой?" />
      <Text
        text="Всем привет! Меня зовут Максим Нестерович. Я — профессиональный танцовщик, хореограф и режиссёр. Более 20 лет я преподаю и делюсь знаниями. На этой странице вы найдёте много полезного для своего развития в танцах и не только. Здесь есть информация о саморазвитии, танцах, улучшении настроения, а также пользе для ума и тела."
        width="67rem"
        margin="3rem 0"
        textAlign="center"
      />
      <div className="about-photo-wrap">
        <div
          className="about-photo"
          style={{ backgroundImage: `url(${photo1})` }}
        ></div>
        <div
          className="about-photo"
          style={{ backgroundImage: `url(${photo2})` }}
        ></div>
        <div
          className="about-photo"
          style={{ backgroundImage: `url(${photo3})` }}
        ></div>
      </div>
      <Text
        text="Благодаря моему большому многолетнему опыту в выступлениях, танцах, самовыражении, работе с телом, преобразовании и создании шоу, танцевальных постановок, хореографии и клипов, а также изучении методик психологии тела, я создал огромный фундамент знаний. Я собрал уникальный метод самопознания и развития через телодвижения и танец, который поможет вам достичь свободы проявления в жизни."
        width="100%"
        margin="3rem 0 0"
        textAlign="center"
      />
    </section>
  );
};
