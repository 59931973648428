import { Link } from "react-router-dom";
import "./PageTitle.css";
import arrow from "../../assets/icons/arrow.png";
import { PageTitleProps } from "./types/pagetitle.type";

export const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <section className="content page-title-wrap">
      <Link to="/" className="page-title-link">
        <img src={arrow} alt="" />
        <p>На главную</p>
      </Link>
      <h1 className="page-title">{title}</h1>
    </section>
  );
};
