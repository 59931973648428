import { ScrollRestoration } from "react-router-dom";
import { Footer } from "../components/Footer/Footer";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { SchoolInfo } from "../components/SchoolInfo/SchoolInfo";
import { SchoolSections } from "../components/SchoolSections/SchoolSections";
import { Thought } from "../components/Thought/Thought";

export const School = () => {
  return (
    <main>
      <ScrollRestoration />
      <PageTitle title="Онлайн школа танцев" />
      <SchoolInfo />
      <SchoolSections />
      <Thought text="Я помогу пройти этот путь, а ты просто танцуй" />
      <Footer />
    </main>
  );
};
