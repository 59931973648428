import "./Banner.css";
import banner from "../../assets/images/banner.jpeg";

export const Banner = () => {
  return (
    <section className="banner">
      <div
        className="banner-bg"
        style={{ backgroundImage: `url(${banner})` }}
      ></div>
      <div className="banner-wrap">
        <div className="content">
          <h1 className="banner-title">Клуб Максима Нестеровича</h1>
          <p className="banner-text">
            Я предлагаю вам начать жить полноценной и яркой жизнью. Это
            возможно, когда вы подружитесь со своим телом и позволите ему
            свободно проявляться, давая вам возможность проживать эмоции и
            состояния, которые требуют освобождения.
          </p>
          <a href="#offers-wrap" className="banner-button">Подробнее</a>
        </div>
      </div>
    </section>
  );
};
